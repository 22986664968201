export const USER_TOKEN_COOKIE = "loopcv-token";
export const API_KEY_COOKIE = "loopcv-key"; // not used anymore
export const ENGLISH_REGEX = /^[aA-zZ\s.-]+$/;
export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
export const NUMBER_REGEX = /^[0-9]*$/;
export const DOMAIN_REGEX = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
export const I18N_LOCAL_STORAGE_NAME = "loopcv_i18n";
export const CHROME_WEB_STORE_EXTENSION_ID = "aleecdoflfiofldfpipjhcjoigokflmp";
export const IP_REGEX = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
export const CV_PDF_SIZE = 3145728;
export const ENGLISH_REGEX_WITH_COMMA = /^[A-Za-z\s,]+$/;

export const PORT_REGEX = /^\d{1,5}$/;

export const urlRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\/\w .-]*)(\?[\w=&;%$-]+)?(#\w*)?$/;

export const Platforms = [
  "LinkedIn",
  "Workable",
  "Whoishiring",
  "ReedCoUk",
  "Monster",
  "SimplyHired",
  "Jooble",
  "Indeed",
  "Dice",
  "CareerAddict",
  "GulfTalent"
];
 
export const usaStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];
